export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [8,[2]],
		"/(app)/admin": [9,[2,3]],
		"/(app)/admin/settings": [10,[2,3]],
		"/(app)/ai-agent-manager": [11,[2,4]],
		"/(app)/ai-agent-manager/ai-agents": [12,[2,4,5]],
		"/(app)/ai-agent-manager/ai-agents/create": [13,[2,4,5]],
		"/(app)/ai-agent-manager/ai-agents/edit": [14,[2,4,5]],
		"/(app)/ai-agent-manager/documents": [15,[2,4]],
		"/(app)/ai-agent-manager/functions": [16,[2,4]],
		"/(app)/ai-agent-manager/functions/create": [17,[2,4]],
		"/(app)/ai-agent-manager/functions/edit": [18,[2,4]],
		"/(app)/ai-agent-manager/prompts": [19,[2,4]],
		"/(app)/ai-agent-manager/prompts/create": [20,[2,4]],
		"/(app)/ai-agent-manager/prompts/edit": [21,[2,4]],
		"/(app)/ai-agent-manager/tools": [22,[2,4]],
		"/(app)/ai-agent-manager/tools/create": [23,[2,4]],
		"/(app)/ai-agent-manager/tools/edit": [24,[2,4]],
		"/auth": [28],
		"/(app)/c/[id]": [25,[2]],
		"/(app)/c/[id]/s/[shareId]": [26,[6]],
		"/error": [29],
		"/(app)/playground": [27,[2,7]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';